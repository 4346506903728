import React, { Component } from 'react';
import { graphql } from 'gatsby';

import ArticleComponent from '../components/articles/article/Article';
import ArticleFragment from './fragments/Article';
import ArticleModel from '../models/Article';
import breadcrumbs from './breadcrumbs';
import jsonLd from './schemas';
import ProductFragment from './fragments/Product';
import ProductModel from '../models/Product';
import { getProductsForSlices } from '../utils/utils';

export const query = graphql`
  query ($id: String!, $productIdsAll: [Int]) {
    sanityArticle(id: { eq: $id }) {
      ...Article
    }
    allInRiverProduct(filter: { entityId: { in: $productIdsAll } }) {
      edges {
        node {
          ...Product
        }
      }
    }
  }
`;

class Article extends Component {
  render() {
    const { brand, site, path } = this.props;

    const { productIdsSlices, siteUID } = this.props.pageContext;
    const { allInRiverProduct, sanityArticle } = this.props.data;

    const products = getProductsForSlices(
      productIdsSlices,
      allInRiverProduct,
      ProductModel,
      siteUID,
      site
    );
    const article = new ArticleModel(sanityArticle, siteUID, products);

    return (
      <ArticleComponent
        article={article}
        breadcrumbs={breadcrumbs('ARTICLE', article)}
        jsonLd={jsonLd(brand, site, path, article)}
        site={site}
      />
    );
  }
}

export default Article;
